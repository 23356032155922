export const colors = [
  '#485760',
  '#5C4D4F',
  '#545440',
  '#325854',
  '#3B5A3A',
  '#3C536D',
  '#435356',
  '#385752',
];
